import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { TextField, Autocomplete } from '@mui/material';
import './Search.css';
import config from '../config';

function Search({ itemKey, dataKey, detailKey, routeBase, label, onSearchFocus }) {
  const navigate = useNavigate();
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const searchInputRef = useRef(null);

  useEffect(() => {
    fetchItems();
  }, []);

  const fetchItems = async () => {
    try {
      const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2F${dataKey}.json?alt=media`);
      const data = response.data;
      
      // Ensure data is an array before sorting
      const sortedItems = Array.isArray(data) ? data.sort((a, b) => a.name.localeCompare(b.name)) : [];
      setItems(sortedItems);
    } catch (error) {
      console.error('Error fetching items:', error);
    }
  };

  const handleItemChange = (event, value) => {
    if (onSearchFocus) {
      onSearchFocus(false);
    }
    if (value) {
      // Check if we're currently on the research tab
      const isResearchTab = window.location.pathname.includes('/research');
      const newPath = isResearchTab 
        ? `/${routeBase}/${value.key}/research`
        : `/${routeBase}/${value.key}`;
      
      navigate(newPath);
      
      // Remove focus from the input
      if (searchInputRef.current) {
        searchInputRef.current.blur();
      }
    }
  };

  const handleFocus = () => {
    setIsFocused(false);
    if (onSearchFocus) {
      onSearchFocus(false); // Center the search bar
    }
  };

  const handleBlur = () => {
    if (!searchQuery) {
      setIsFocused(false);
      if (onSearchFocus) {
        onSearchFocus(false); // Reset search bar position if no query
      }
    }
  };

  return (
    <div className={`search-bar ${isFocused ? 'focused' : ''} ${itemKey ? 'top' : 'center'} `}>
      <Autocomplete
        options={items}
        getOptionLabel={(option) => option.name}
        onChange={handleItemChange}
        inputValue={searchQuery}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            inputRef={searchInputRef}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        )}
      />
    </div>
  );
}

export default Search;