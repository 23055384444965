import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Search from './Search';
import Detail from './Detail';

function Conditions() {
  const { conditionKey } = useParams(); // Get the condition key from the URL if available
  const [searchFocused, setSearchFocused] = useState(false);

  useEffect(() => {
    console.log('Condition key:', conditionKey); // Debugging
    setSearchFocused(!!conditionKey);
  }, [conditionKey]);

  const handleLoadingComplete = () => {
    setSearchFocused(true); // Reset search focused state after loading completes
  };

  const handleSearchFocus = (focused) => {
    setSearchFocused(focused);
  };

  return (
    <div className={`conditions-page ${searchFocused ? 'top' : 'center'}`}>
      <Search 
        itemKey={conditionKey}
        dataKey="conditions"
        detailKey="card"
        routeBase="conditions"
        label="Search conditions..."
        onSearchFocus={handleSearchFocus}
      />
      {conditionKey && (
        <Detail 
          dataKey="conditions"
          detailKey="card"
          onLoadingComplete={handleLoadingComplete}
        />
      )}
    </div>
  );
}

export default Conditions;
