import React, { useState, useEffect } from 'react';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, signInWithPopup, GoogleAuthProvider, signInWithCredential } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Container, TextField, Button, Typography, Box, Paper, Link, Checkbox, FormControlLabel, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import './Home.css';
import dayjs from 'dayjs';
import { sendEmailVerification } from 'firebase/auth'; 

function Home() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [agreed, setAgreed] = useState(false);
  const [isResetPassword, setIsResetPassword] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const [resetMessage, setResetMessage] = useState('');
  const [message, setMessage] = useState('');
  const [isRegistering, setIsRegistering] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const auth = getAuth();
  const googleProvider = new GoogleAuthProvider();

  useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    // Enable the "Register" button only when all fields are filled and passwords match
    if (email && firstName && lastName && dateOfBirth && password && confirmPassword && agreed && passwordsMatch) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [email, firstName, lastName, dateOfBirth, password, confirmPassword, agreed, passwordsMatch]);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordsMatch(e.target.value === confirmPassword);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordsMatch(e.target.value === password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      setMessage('Email and password are required');
      return;
    }
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
  
      // Check if the user's email is verified
      if (!user.emailVerified) {
        setMessage('Please verify your email before logging in.');
        auth.signOut();
        navigate('/verify-email'); // Redirect to VerifyEmail screen
        return;
      }
  
      setMessage('Login successful!');
      navigate('/dashboard'); // Redirect to Dashboard
    } catch (error) {
      setMessage(`Login failed: ${error.message}`);
    }
  };
  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (!resetEmail) {
      setResetMessage('Email is required for password reset');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, resetEmail);
      setResetMessage('Password reset email sent! Check your inbox.');
    } catch (error) {
      setResetMessage(`Failed to send reset email: ${error.message}`);
    }
  };


// Common function to handle Firebase authentication and Firestore user data
const handleFirebaseSignIn = async (credential) => {
  try {
    const result = await signInWithCredential(auth, credential);
    const user = result.user;

    // Check if the user exists in Firestore
    const userDoc = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userDoc);

    // If the user doesn't exist, create a new document for them
    if (!docSnap.exists()) {
      const displayName = user.displayName ? user.displayName.split(' ') : ['First', 'Last'];
      await setDoc(userDoc, {
        email: user.email,
        firstName: displayName[0],
        lastName: displayName[1],
        dateOfBirth: '', // Prompt user to fill in
        gender: '', // Prompt user to fill in
      });
    }

    // Check if user profile is complete
    const userData = docSnap.data();
    if (!userData || !userData.dateOfBirth) {
      setMessage('Please complete your profile');
      navigate('/profile'); // Navigate to profile page if profile is incomplete
    } else {
      setMessage('Google sign-in successful!');
      navigate('/dashboard'); // Navigate to dashboard if sign-in is successful
    }
  } catch (error) {
    setMessage(`Google sign-in failed: ${error.message}`);
  }
};

// Web-based Google sign-in using popup
const handleGoogleSignIn = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    const credential = GoogleAuthProvider.credential(result._tokenResponse.idToken);
    await handleFirebaseSignIn(credential); // Reuse common logic
  } catch (error) {
    setMessage(`Google sign-in failed: ${error.message}`);
  }
};

// Native Google sign-in using token passed from Android
window.handleGoogleToken = async function (token) {
  try {
    const credential = GoogleAuthProvider.credential(token);
    await handleFirebaseSignIn(credential); // Reuse common logic
  } catch (error) {
    setMessage(`Google sign-in failed: ${error.message}`);
  }
};

 

 const handleDateChange = (e) => {
    const selectedDate = dayjs(e.target.value);
    const today = dayjs();
    const age = today.diff(selectedDate, 'year');

    if (age < 18) {
      setMessage('You must be at least 18 years old.');
    } else {
      setMessage('');
    }

    setDateOfBirth(e.target.value);
  };

  const handleRegister = async (e) => {
    e.preventDefault();

    // Check if user is at least 18 years old
    const selectedDate = dayjs(dateOfBirth);
    const today = dayjs();
    const age = today.diff(selectedDate, 'year');
    if (age < 18) {
      setMessage('You must be at least 18 years old.');
      return;
    } else {
      setMessage('');
    }
    const rootUrl = `${window.location.protocol}//${window.location.host}`;

    // Configure the actionCodeSettings
    const actionCodeSettings = {
      url: rootUrl+'/login',  // This will redirect the user to the root of the current URL
      handleCodeInApp: true,  // Ensures the link is handled within the app
    };
  
    try {
      // Create user with email and password
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send email verification
      await sendEmailVerification(user, actionCodeSettings);
      
      // Save additional user information to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email,
        firstName,
        lastName,
        dateOfBirth,
        gender,
        emailVerified: false // Initially set emailVerified to false
      });

      // Inform the user to check their email for verification
      setMessage('Registration successful! Please verify your email before logging in.');

      // Sign out the user after registration
      auth.signOut();

      // Redirect the user to the VerifyEmail screen
      navigate('/verify-email');  // Adjust the path to your actual VerifyEmail route

    } catch (error) {
      setMessage(`Registration failed: ${error.message}`);
    }
};

  const eighteenYearsAgo = dayjs().subtract(18, 'year').format('YYYY-MM-DD');

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 8 }}>
        <Typography component="h1" variant="h5" gutterBottom>
          {isRegistering ? 'Register a New Account' : isResetPassword ? 'Reset Password' : 'Login to Your Account'}
        </Typography>
        {isRegistering ? (
          <Box component="form" onSubmit={handleRegister} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="dateOfBirth"
              label="Date of Birth"
              name="dateOfBirth"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                max: eighteenYearsAgo, // Restrict to dates 18 years ago or earlier
              }}
              value={dateOfBirth}
              onChange={(e) => setDateOfBirth(e.target.value)}
            />
            <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel id="gender-label">Gender</InputLabel>
                  <Select
                    labelId="gender-label"
                    id="gender"
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                    label="Gender"
                    required
                  >
                    <MenuItem value="">
                      <em>Select Gender</em>
                    </MenuItem>
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                  </Select>
            </FormControl>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="new-password"
              value={password}
              onChange={handlePasswordChange}
              error={!passwordsMatch}
              helperText={!passwordsMatch ? 'Passwords do not match' : ''}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              id="confirmPassword"
              autoComplete="new-password"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              error={!passwordsMatch}
              helperText={!passwordsMatch ? 'Passwords do not match' : ''}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                />
              }
              label={
                <Typography variant="body2">
                  I agree to the <Link href="/terms-and-conditions" target="_blank">Terms and Conditions</Link> and the <Link href="/privacy-policy" target="_blank">Privacy Policy</Link>.
                </Typography>
              }
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              disabled={!formValid}
            >
              Register
            </Button>
            {message && <Typography color="error">{message}</Typography>}
            <Button
              fullWidth
              variant="text"
              onClick={() => setIsRegistering(false)}
              sx={{ mt: 2 }}
            >
              Already have an account? Login
            </Button>
          </Box>
        ) : isResetPassword ? (
          <Box component="form" onSubmit={handleResetPassword} noValidate sx={{ mt: 1 }}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="resetEmail"
              label="Email Address"
              name="resetEmail"
              autoComplete="email"
              autoFocus
              value={resetEmail}
              onChange={(e) => setResetEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
              >
                Send Reset Email
              </Button>
              {resetMessage && <Typography color="error">{resetMessage}</Typography>}
              <Button
                fullWidth
                variant="text"
                onClick={() => {
                  setIsResetPassword(false);
                  setResetMessage('');
                }}
                sx={{ mt: 2 }}
              >
                Back to Login
              </Button>
            </Box>
          ) : (
            <>
              <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => {
                    setIsResetPassword(true);
                    setMessage('');
                  }}
                  sx={{ display: 'block', marginTop: 1, textAlign: 'right' }}
                >
                  Forgot Password?
                </Link>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Login
                </Button>
                {message && <Typography color="error">{message}</Typography>}
              </Box>
              <Button
                fullWidth
                variant="text"
                onClick={() => {
                  setIsRegistering(true);
                  setMessage('');
                }}
                sx={{ mt: 2 }}
              >
                New here? Register
              </Button>
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                or
              </Typography>
  
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 0 }}>
                <button
                  className="gsi-material-button"
                  onClick={handleGoogleSignIn}
                  style={{ border: 'none', padding: 0, background: 'none' }}
                >
                  <div className="gsi-material-button-state"></div>
                  <div className="gsi-material-button-content-wrapper">
                    <div className="gsi-material-button-icon">
                      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" style={{ display: 'block' }}>
                        <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                        <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                        <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                        <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                        <path fill="none" d="M0 0h48v48H0z"></path>
                      </svg>
                    </div>
                    <span className="gsi-material-button-contents">Continue with Google</span>
                    <span style={{ display: 'none' }}>Continue with Google</span>
                  </div>
                </button>
              </Box>
              <Typography variant="body2" align="center" sx={{ mt: 2 }}>
                By continuing you are agreeing to the <Link href="/terms-of-use" target="_blank">Terms of Use</Link> and the <Link href="/privacy-policy" target="_blank">Privacy Policy</Link>.
              </Typography>
            </>
          )}
        </Paper>
      </Container>
    );
  }
  
  export default Home;