import React, { useState, useEffect } from 'react';
import { getAuth, updateProfile, updatePassword, reauthenticateWithCredential, EmailAuthProvider, deleteUser } from 'firebase/auth';
import { doc, getDoc, updateDoc, deleteDoc, collection, getDocs, query, where, writeBatch } from 'firebase/firestore';
import { Container, TextField, Button, Typography, Box, Paper, Link, FormControl, InputLabel, Select, MenuItem, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { db } from '../firebase';
import dayjs from 'dayjs';

function Profile() {
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [dateOfBirth, setDateOfBirth] = useState('');
  const [gender, setGender] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isUpdateDisabled, setIsUpdateDisabled] = useState(true);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const auth = getAuth();

  useEffect(() => {
    if (currentUser) {
      setEmail(currentUser.email);
      setFirstName(currentUser.displayName?.split(' ')[0] || '');
      setLastName(currentUser.displayName?.split(' ')[1] || '');
      getProfileData();
    }
  }, [currentUser]);

  const getProfileData = async () => {
    if (currentUser) {
      const userDoc = doc(db, 'users', currentUser.uid);
      const docSnap = await getDoc(userDoc);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setFirstName(data.firstName || '');
        setLastName(data.lastName || '');
        setDateOfBirth(data.dateOfBirth || '');
        setGender(data.gender || '');
      }
    }
  };

  useEffect(() => {
    setIsUpdateDisabled(!dateOfBirth);
  }, [dateOfBirth]);

  const handleUpdateProfile = async (e) => {
    e.preventDefault();
    const selectedDate = dayjs(dateOfBirth);
    const today = dayjs();
    const age = today.diff(selectedDate, 'year', true);
    if (age < 18) {
      setMessage('You must be at least 18 years old to use myStack');
      return;
    }
    if (currentUser) {
      try {
        await updateProfile(currentUser, {
          displayName: `${firstName} ${lastName}`,
        });
        await updateDoc(doc(db, 'users', currentUser.uid), {
          firstName,
          lastName,
          dateOfBirth,
          gender,
        });
        setMessage('Profile updated successfully');
        navigate('/dashboard');
      } catch (error) {
        setMessage(`Failed to update profile: ${error.message}`);
      }
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword.trim() !== confirmNewPassword.trim()) {
      setMessage('New passwords do not match');
      return;
    }
    try {
      const user = auth.currentUser;

      // Reauthenticate user with the current password
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Update the password
      await updatePassword(user, newPassword);
      setMessage('Password updated successfully');
      handleClosePasswordModal();
    } catch (error) {
      setMessage(`Failed to update password: ${error.message}`);
    }
  };

  const handleDeleteAccount = async () => {
    if (!currentPassword.trim()) {
      setMessage('Password is required to delete the account.');
      setIsDeleteDialogOpen(false);
      return;
    }
    if (currentUser) {
      try {
        // Reauthenticate user before deletion
        const credential = EmailAuthProvider.credential(currentUser.email, currentPassword);
        await reauthenticateWithCredential(currentUser, credential);

        // Batch deletion to ensure all data is deleted atomically
        const batch = writeBatch(db);

        // Delete user document
        const userRef = doc(db, 'users', currentUser.uid);
        batch.delete(userRef);

        // Delete stacks associated with user
        const stacksQuery = query(collection(db, 'stacks'), where('userId', '==', currentUser.uid));
        const stacksSnapshot = await getDocs(stacksQuery);
        stacksSnapshot.docs.forEach((stackDoc) => {
          batch.delete(stackDoc.ref);
        });

        // Delete health goals associated with user
        const goalsQuery = query(collection(db, 'healthGoals'), where('userId', '==', currentUser.uid));
        const goalsSnapshot = await getDocs(goalsQuery);
        goalsSnapshot.docs.forEach((goalDoc) => {
          batch.delete(goalDoc.ref);
        });

        // Delete notes associated with user
        const notesQuery = query(collection(db, 'notes'), where('userId', '==', currentUser.uid));
        const notesSnapshot = await getDocs(notesQuery);
        notesSnapshot.docs.forEach((noteDoc) => {
          batch.delete(noteDoc.ref);
        });

        // Commit batch
        await batch.commit();

        // Delete user from Firebase Authentication
        await deleteUser(currentUser);

        setMessage('Account deleted successfully.');
        navigate('/'); // Redirect to landing page after deletion
      } catch (error) {
        setMessage(`Failed to delete account: ${error.message}`);
        setIsDeleteDialogOpen(false);
    }
    }
  };

  const handleOpenPasswordModal = (e) => {
    e.preventDefault();
    setIsPasswordModalOpen(true);
  };

  const handleClosePasswordModal = () => {
    setIsPasswordModalOpen(false);
    setCurrentPassword('');
    setNewPassword('');
    setConfirmNewPassword('');
    setMessage('');
  };

  const eighteenYearsAgo = dayjs().subtract(18, 'year').format('YYYY-MM-DD');

  return (
    <Container component="main" maxWidth="xs">
      <Paper elevation={3} sx={{ padding: 2, marginTop: 6 }}>
        <Typography component="h1" variant="h5" gutterBottom>
          Profile
        </Typography>
        <Box component="form" onSubmit={handleUpdateProfile} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            disabled
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="firstName"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="lastName"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="dateOfBirth"
            label="Date of Birth"
            name="dateOfBirth"
            type="date"
            InputLabelProps={{ shrink: true }}
            inputProps={{
              max: eighteenYearsAgo,
            }}
            value={dateOfBirth}
            onChange={(e) => setDateOfBirth(e.target.value)}
          />
          <FormControl fullWidth variant="outlined" margin="normal">
            <InputLabel id="gender-label">Gender</InputLabel>
            <Select
              labelId="gender-label"
              id="gender"
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              label="Gender"
            >
              <MenuItem value="">
                <em>Prefer not to say</em>
              </MenuItem>
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
          </FormControl>

          <Button
            component="button"
            variant="body2"
            onClick={handleOpenPasswordModal}
            sx={{ display: 'block', marginTop: 2 }}
          >
            Change Password
          </Button>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isUpdateDisabled}
            sx={{ mt: 3, mb: 2 }}
          >
            Update Profile
          </Button>
          {message && <Typography color="error">{message}</Typography>}
        </Box>
      </Paper>

      <Link
        component="button"
        variant="body2"
        onClick={() => setIsDeleteDialogOpen(true)}
        sx={{ mt: 2, display: 'block', color: 'black', textAlign: 'center' }}
      >
        Delete Account
      </Link>

      <Dialog open={isDeleteDialogOpen} onClose={() => setIsDeleteDialogOpen(false)}>
        <DialogTitle>Delete Account</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deleting your account is permanent and will remove all your data. This action cannot be undone. Please enter your password to confirm.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="deletePassword"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDeleteDialogOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            Delete Account
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isPasswordModalOpen} onClose={handleClosePasswordModal}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter your current password and the new password you would like to set.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="currentPassword"
            label="Current Password"
            type="password"
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            name="newPassword"
            label="New Password"
            type="password"
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <TextField
            margin="dense"
            name="confirmNewPassword"
            label="Confirm New Password"
            type="password"
            fullWidth
            value={confirmNewPassword}
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
          {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePasswordModal} color="primary">
            Cancel
          </Button>
          <Button onClick={handleChangePassword} color="primary">
            Update Password
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default Profile;