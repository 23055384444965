import { colors } from '../../theme';

export const buttonStyles = {
    primary: {
      height: '40px',
      borderRadius: '10px',
      textTransform: 'none',
      fontSize: '0.875rem',
      background: theme => theme.gradients.primary,
      boxShadow: theme => theme.customShadows.button,
      '&:hover': {
        background: theme => theme.gradients.hover,
        boxShadow: theme => theme.customShadows.buttonHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.grey.border,
      }
    },
    secondary: {
      height: '40px',
      borderRadius: '10px',
      textTransform: 'none',
      borderColor: colors.grey.border,
      color: colors.green.medium,
      '&:hover': {
        borderColor: colors.green.medium,
        backgroundColor: colors.green.transparent.medium,
        boxShadow: theme => theme.customShadows.button,
      }
    },
    iconWrapper: {
      display: 'flex',
      alignItems: 'center',
      gap: 1
    }
  };