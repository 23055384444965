import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  Typography,
  InputLabel,
  Box,
  Grid,
  Card,
  CardContent,
  Link,
  Menu,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Fab,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CreatableSelect from 'react-select/creatable';
import config from '../config';
import { tableCellClasses } from '@mui/material/TableCell';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTheme } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const AddSupplementCard = styled(Card)(({ theme }) => ({
  background: 'linear-gradient(145deg, #ffffff 0%, #f5f7fa 100%)',
  boxShadow: '0 8px 32px -8px rgba(0,0,0,0.1)',
  borderRadius: '16px',
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'translateY(-2px)',
  },
}));

const SupplementsTable = ({
  selectedSupplements,
  setSelectedSupplements,
  notes,
  setNotes
}) => {
  const [supplements, setSupplements] = useState([]);
  const [supplementDetails, setSupplementDetails] = useState({});
  const [newSupplement, setNewSupplement] = useState(null);
  const [newDosage, setNewDosage] = useState('');
  const [newUnit, setNewUnit] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuOpenIndex, setMenuOpenIndex] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editDosage, setEditDosage] = useState('');
  const [editUnit, setEditUnit] = useState('');
  const [supplementOptions, setSupplementOptions] = useState([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [currentSupplementDetails, setCurrentSupplementDetails] = useState(null);
  const theme = useTheme();

  // Fetch basic supplement list for the dropdown
  useEffect(() => {
    const fetchSupplements = async () => {
      try {
        const response = await axios.get(`${config.FIREBASE_STORAGE_BASE_URL}config%2Fsupplements.json?alt=media`);
        const supplements = response.data;
        
        // Transform the data for CreatableSelect using key and name
        const options = supplements.map((supplement) => ({
          label: supplement.name,
          value: supplement.key,
        }));
        
        // Sort alphabetically by label
        options.sort((a, b) => a.label.localeCompare(b.label));
        
        setSupplementOptions(options);
      } catch (error) {
        console.error('Error fetching supplements:', error);
      }
    };

    fetchSupplements();
  }, []);

  // Add a function to generate or get stack ID from URL
  const getStackId = () => {
    const params = new URLSearchParams(window.location.search);
    let stackId = params.get('tempId');
    
    if (!stackId) {
      // Generate a new ID if none exists
      stackId = 'temp_' + Date.now();
      params.set('tempId', stackId);
      const newUrl = `${window.location.pathname}?${params.toString()}`;
      window.history.replaceState({}, '', newUrl);
    }
    
    return stackId;
  };

  // Update handleArticleClick to save notes from props
  const handleArticleClick = (supplementId, e) => {
    e.preventDefault();
    if (!supplementId) return;

    const stackId = getStackId();
    
    // Save current stack state with all form data
    const stackState = {
      supplements: selectedSupplements,
      stackName: document.querySelector('input[type="text"]')?.value || '',
      healthGoals: document.querySelector('textarea[placeholder*="health goals"]')?.value || '',
      notes: notes,
      timestamp: new Date().getTime()
    };
    
    localStorage.setItem(`unsavedStack_${stackId}`, JSON.stringify(stackState));
    window.location.href = `/supplements/${supplementId}?tempId=${stackId}`;
  };

  const handleAddSupplement = async () => {
    if (!newSupplement || !newDosage || !newUnit) return;

    try {
      const response = await axios.get(
        `${config.FIREBASE_STORAGE_BASE_URL}supplements%2F${newSupplement.value}.json?alt=media`
      );

      const noteContent = response.data?.note?.content || 
                         response.data?.['note:']?.content || 
                         'No note available';

      const newSupplementItem = {
        name: newSupplement.label,
        supplementId: newSupplement.value,
        dosage: newDosage,
        unit: newUnit,
        note: noteContent
      };

      setSelectedSupplements([...selectedSupplements, newSupplementItem]);

      // Close modal first
      setAddModalOpen(false);
      
      // Then reset form
      setNewSupplement(null);
      setNewDosage('');
      setNewUnit('');
    } catch (error) {
      // Add the supplement even if we can't get the note
      const newSupplementItem = {
        name: newSupplement.label,
        supplementId: newSupplement.value,
        dosage: newDosage,
        unit: newUnit,
        note: 'Note unavailable'
      };
      setSelectedSupplements([...selectedSupplements, newSupplementItem]);
      
      // Close modal first
      setAddModalOpen(false);
      
      // Then reset form
      setNewSupplement(null);
      setNewDosage('');
      setNewUnit('');
    }
  };

  const removeSupplement = (index) => {
    const updatedSupplements = selectedSupplements.filter((_, i) => i !== index);
    setSelectedSupplements(updatedSupplements);
  };

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget);
    setMenuOpenIndex(index);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuOpenIndex(null);
  };

  const handleEditClick = (index) => {
    const supplement = selectedSupplements[index];
    setEditIndex(index);
    setEditDosage(supplement.dosage);
    setEditUnit(supplement.unit);
    setEditDialogOpen(true);
    handleMenuClose();
  };

  const handleEditSave = () => {
    const updatedSupplements = [...selectedSupplements];
    updatedSupplements[editIndex] = {
      ...updatedSupplements[editIndex],
      dosage: editDosage,
      unit: editUnit,
    };
    setSelectedSupplements(updatedSupplements);
    setEditDialogOpen(false);
  };

  const handleDeleteClick = (index) => {
    removeSupplement(index);
    handleMenuClose();
  };

  const getArticleLink = (supplementId) => {
    if (!supplementId) return null;
    return `${window.location.origin}/supplements/${supplementId}`;
  };

  const handleOpenAddModal = () => {
    setAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setAddModalOpen(false);
    // Reset form
    setNewSupplement(null);
    setNewDosage('');
    setNewUnit('');
  };

  return (
    <>
      <TableContainer component={Paper} sx={{ marginTop: 2 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <StyledTableCell>Supplement</StyledTableCell>
              <StyledTableCell align="right">Dosage</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedSupplements.length > 0 ? (
              selectedSupplements.map((supplement, index) => (
                <React.Fragment key={index}>
                  <StyledTableRow>
                    <StyledTableCell>
                      <Box sx={{ 
                        display: 'flex', 
                        alignItems: 'flex-start',
                        justifyContent: 'space-between'
                      }}>
                        <Box sx={{ flex: 1, pr: 2 }}>
                          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                              {supplement.name}
                            </Typography>
                            <IconButton
                              aria-label="more"
                              aria-controls={`menu-${index}`}
                              aria-haspopup="true"
                              onClick={(e) => handleMenuClick(e, index)}
                              sx={{ 
                                padding: 0.5,
                                '&:hover': {
                                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                }
                              }}
                            >
                              <MoreVertIcon sx={{ fontSize: 18 }} />
                            </IconButton>
                          </Box>
                          <Typography variant="body2" color="textSecondary">
                            {supplement.note || 'No note available'}
                            <Link
                              component="button"
                              onClick={(e) => handleArticleClick(supplement.supplementId, e)}
                              sx={{ 
                                marginLeft: 1,
                                textDecoration: 'none',
                                border: 'none',
                                background: 'none',
                                padding: 0,
                                color: 'primary.main',
                                cursor: 'pointer',
                                fontSize: 'inherit',
                                fontFamily: 'inherit',
                                '&:hover': {
                                  textDecoration: 'underline'
                                }
                              }}
                            >
                              Read more
                            </Link>
                          </Typography>
                        </Box>
                      </Box>

                      {/* Add Menu component */}
                      <Menu
                        id={`menu-${index}`}
                        anchorEl={anchorEl}
                        open={menuOpenIndex === index}
                        onClose={handleMenuClose}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                      >
                        <MenuItem onClick={() => handleEditClick(index)}>
                          <EditIcon sx={{ mr: 1, fontSize: 18 }} />
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => handleDeleteClick(index)}>
                          <CloseIcon sx={{ mr: 1, fontSize: 18 }} />
                          Remove
                        </MenuItem>
                      </Menu>
                    </StyledTableCell>
                    <StyledTableCell align="right">
                      <Typography variant="body1">
                        {supplement.dosage} {supplement.unit}
                      </Typography>
                    </StyledTableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell colSpan={2} align="center">
                  <Typography variant="body1" color="textSecondary">
                    No supplements added yet.
                  </Typography>
                </StyledTableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Edit Supplement Dialog */}
      <Dialog
        open={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        PaperProps={{
          sx: {
            borderRadius: '16px',
            margin: { xs: '16px', sm: '32px' },
            position: 'absolute',
            top: { 
              xs: '20%',
              sm: '50%'
            },
            transform: { 
              xs: 'translateY(-20%)',
              sm: 'translateY(-50%)'
            },
            maxHeight: { 
              xs: 'auto',
              sm: 'none'
            },
            width: { xs: 'calc(100% - 32px)', sm: 'auto' }
          }
        }}
        sx={{
          '& .MuiDialog-container': {
            alignItems: 'flex-start',
            paddingTop: { xs: '1rem', sm: 0 }
          }
        }}
      >
        <DialogTitle>Edit Supplement</DialogTitle>
        <DialogContent>
          <TextField
            label="Dosage"
            type="number"
            fullWidth
            value={editDosage}
            onChange={(e) => setEditDosage(e.target.value)}
            margin="normal"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel>Unit</InputLabel>
            <Select
              value={editUnit}
              onChange={(e) => setEditUnit(e.target.value)}
              label="Unit"
            >
              {Object.entries(config.UNITS).map(([key, label]) => (
                <MenuItem key={key} value={label}>
                  {label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
          <Button onClick={handleEditSave} variant="contained" color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Supplement Section */}
      <Fab
        color="primary"
        aria-label="add supplement"
        onClick={handleOpenAddModal}
        size="medium"
        sx={{
          mt: 2,
          background: theme.gradients.primary,
          boxShadow: theme.customShadows.button,
          '&:hover': {
            background: theme.gradients.hover,
            boxShadow: theme.customShadows.buttonHover,
          },
        }}
      >
        <AddIcon />
      </Fab>

      <Dialog
        open={addModalOpen}
        onClose={handleCloseAddModal}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            boxShadow: '0 8px 32px -8px rgba(0,0,0,0.2)',
            margin: { xs: '16px', sm: '32px' },
            position: 'absolute',
            top: { xs: 0, sm: '50%' },
            transform: { 
              xs: 'none',
              sm: 'translateY(-50%)'
            },
            maxHeight: { 
              xs: 'calc(100% - 32px)',
              sm: 'none' 
            },
          }
        }}
        sx={{
          '& .MuiDialog-container': {
            alignItems: { xs: 'flex-start', sm: 'center' }
          }
        }}
      >
        <DialogTitle sx={{ 
          pb: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}>
          <AddIcon color="primary" />
          <Typography variant="h6" component="span" color="primary">
            Add New Supplement
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseAddModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        
        <DialogContent sx={{ pt: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CreatableSelect
                options={supplementOptions}
                value={newSupplement}
                onChange={(newValue) => setNewSupplement(newValue)}
                placeholder="Search or add a supplement..."
                formatCreateLabel={(inputValue) => `Create "${inputValue}"`}
                styles={{
                  control: (base) => ({
                    ...base,
                    minHeight: '48px',
                    borderRadius: '12px',
                    border: '1px solid #e0e0e0',
                    boxShadow: 'none',
                    '&:hover': {
                      border: '1px solid #90caf9',
                    },
                  }),
                  menu: (base) => ({
                    ...base,
                    zIndex: 9999,
                    borderRadius: '12px',
                    overflow: 'hidden',
                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                  }),
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Dosage"
                type="number"
                value={newDosage}
                onChange={(e) => setNewDosage(e.target.value)}
                InputProps={{
                  inputProps: { min: 0, step: 'any' },
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '12px',
                  },
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Unit</InputLabel>
                <Select
                  value={newUnit}
                  onChange={(e) => setNewUnit(e.target.value)}
                  label="Unit"
                  sx={{ borderRadius: '12px' }}
                >
                  {Object.entries(config.UNITS).map(([key, label]) => (
                    <MenuItem key={key} value={label}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>

        <DialogActions sx={{ p: 2.5, pt: 1.5 }}>
          <Button
            onClick={handleCloseAddModal}
            sx={{ 
              borderRadius: '10px',
              textTransform: 'none',
              minWidth: '100px',
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleAddSupplement}
            disabled={!newSupplement || !newDosage || !newUnit}
            sx={{
              borderRadius: '10px',
              textTransform: 'none',
              minWidth: '100px',
              background: 'linear-gradient(45deg, #2E8B57 30%, #3CB371 90%)',
              boxShadow: '0 3px 12px rgba(46, 139, 87, 0.3)',
              '&:hover': {
                background: 'linear-gradient(45deg, #2E8B57 30%, #3CB371 90%)',
                boxShadow: '0 4px 15px rgba(46, 139, 87, 0.4)',
              },
            }}
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>

      {/* Supplement Details Modal */}
      <Dialog
        open={detailsModalOpen}
        onClose={() => setDetailsModalOpen(false)}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            borderRadius: '16px',
            margin: { xs: '16px', sm: '32px' },
            maxHeight: { 
              xs: 'calc(100% - 32px)', 
              sm: '80vh' 
            },
          }
        }}
      >
        <DialogTitle sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          pb: 1
        }}>
          {currentSupplementDetails?.name || 'Supplement Details'}
          <IconButton 
            onClick={() => setDetailsModalOpen(false)}
            sx={{ color: '#718096' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent sx={{ 
          pt: 2,
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#CBD5E0',
            borderRadius: '4px',
          }
        }}>
          {currentSupplementDetails ? (
            <>
              {/* Overview Section */}
              {currentSupplementDetails.overview && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" sx={{ color: '#2D3748', mb: 1 }}>
                    Overview
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ 
                      color: '#4A5568',
                      whiteSpace: 'pre-line',
                      backgroundColor: '#F7FAFC',
                      p: 2,
                      borderRadius: '8px',
                      border: '1px solid #E2E8F0'
                    }}
                  >
                    {currentSupplementDetails.overview}
                  </Typography>
                </Box>
              )}

              {/* Benefits Section */}
              {currentSupplementDetails.benefits && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" sx={{ color: '#2D3748', mb: 1 }}>
                    Benefits
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ 
                      color: '#4A5568',
                      whiteSpace: 'pre-line',
                      backgroundColor: '#F7FAFC',
                      p: 2,
                      borderRadius: '8px',
                      border: '1px solid #E2E8F0'
                    }}
                  >
                    {currentSupplementDetails.benefits}
                  </Typography>
                </Box>
              )}

              {/* Side Effects Section */}
              {currentSupplementDetails.sideEffects && (
                <Box sx={{ mb: 3 }}>
                  <Typography variant="subtitle2" sx={{ color: '#2D3748', mb: 1 }}>
                    Side Effects
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ 
                      color: '#4A5568',
                      whiteSpace: 'pre-line',
                      backgroundColor: '#F7FAFC',
                      p: 2,
                      borderRadius: '8px',
                      border: '1px solid #E2E8F0'
                    }}
                  >
                    {currentSupplementDetails.sideEffects}
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Typography color="text.secondary">
              No detailed information available for this supplement.
            </Typography>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SupplementsTable;