import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, TextField, Paper, IconButton, InputAdornment, CircularProgress, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, addDoc, doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import useUserProfile from '../hooks/useUserProfile';
import SupplementsTable from './SupplementsTable';
import AnalyzeWithAI from './AnalyzeWithAI';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { buttonStyles } from './common/ButtonStyles';
import axios from 'axios';
import config from '../config';
import { calculateAge } from '../Utils';
import CloseIcon from '@mui/icons-material/Close';

const { BASE_URL } = config;

const auth = getAuth();

function StackForm({ isEditMode, stackId }) {
  const [stackName, setStackName] = useState('');
  const [healthGoals, setHealthGoals] = useState('');
  const [notes, setNotes] = useState('');
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [healthGoalsId, setHealthGoalsId] = useState('');
  const [notesId, setNotesId] = useState('');
  const [analysisResults, setAnalysisResults] = useState(null);
  const { userProfile, loading } = useUserProfile();
  const navigate = useNavigate();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const [isAnalyzing, setIsAnalyzing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showStackDetails, setShowStackDetails] = useState(false);
  const [isInitialLoadComplete, setIsInitialLoadComplete] = useState(false);

  useEffect(() => {
    if (isEditMode && stackId) {
      const fetchStack = async () => {
        try {
          const docRef = doc(db, 'stacks', stackId);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            const stackData = docSnap.data();
            setStackName(stackData.name);
            setSelectedSupplements(stackData.supplements);

            // Fetch health goals and notes
            const healthGoalsRef = doc(db, 'healthGoals', stackData.healthGoalsRef);
            const healthGoalsSnap = await getDoc(healthGoalsRef);
            if (healthGoalsSnap.exists()) {
              setHealthGoals(healthGoalsSnap.data().goals);
              setHealthGoalsId(healthGoalsRef.id);
            }

            const notesRef = doc(db, 'notes', stackData.notesRef);
            const notesSnap = await getDoc(notesRef);
            if (notesSnap.exists()) {
              setNotes(notesSnap.data().content);
              setNotesId(notesRef.id);
            }
          }
        } catch (error) {
          console.error('Error fetching stack:', error);
        } finally {
          setIsInitialLoadComplete(true);
        }
      };

      fetchStack();
    } else {
      setIsInitialLoadComplete(true);
    }
  }, [isEditMode, stackId]);

  useEffect(() => {
    if (!isInitialLoadComplete) return;

    const params = new URLSearchParams(window.location.search);
    const tempId = params.get('tempId');
    
    if (tempId) {
      const savedStack = localStorage.getItem(`unsavedStack_${tempId}`);
      
      if (savedStack) {
        try {
          const parsedStack = JSON.parse(savedStack);
          const { supplements, stackName: savedName, healthGoals: savedGoals, notes: savedNotes, timestamp } = parsedStack;
          
          const oneHour = 60 * 60 * 1000;
          if (new Date().getTime() - timestamp < oneHour) {
            setSelectedSupplements(prev => supplements || prev);
            setStackName(prev => savedName || prev);
            setHealthGoals(prev => savedGoals || prev);
            setNotes(prev => savedNotes || prev);
            setShowStackDetails(true);
            
            // Clear tempId from URL without page reload
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('tempId');
            window.history.replaceState({}, '', newUrl);
          }
        } catch (error) {
          console.error('Error restoring stack state:', error);
        }
      }
    }
  }, [isInitialLoadComplete]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const user = auth.currentUser;

    if (!stackName) {
      setErrorMessage('Stack name is required.');
      return;
    }

    if (user) {
      try {
        if (isEditMode) {
          // Update health goals document
          const healthGoalsRef = doc(db, 'healthGoals', healthGoalsId);
          await updateDoc(healthGoalsRef, { goals: healthGoals });

          // Update notes document
          const notesRef = doc(db, 'notes', notesId);
          await updateDoc(notesRef, { content: notes });

          // Update stack document
          const stackRef = doc(db, 'stacks', stackId);
          await updateDoc(stackRef, {
            name: stackName,
            supplements: selectedSupplements,
            updatedBy: user.displayName || user.email || 'unknown',
          });

          console.log('Stack updated successfully');
          setErrorMessage(''); // Clear error message on successful save
          navigate('/dashboard'); // Navigate to dashboard on successful update
        } else {
          // Create health goals document
          const healthGoalDocRef = await addDoc(collection(db, 'healthGoals'), {
            goals: healthGoals,
            userId: user.uid
          });

          // Create notes document
          const notesDocRef = await addDoc(collection(db, 'notes'), {
            content: notes,
            userId: user.uid
          });

          // Create stack document with references to health goals and notes
          const stack = {
            name: stackName,
            userId: user.uid,
            supplements: selectedSupplements,
            healthGoalsRef: healthGoalDocRef.id,
            notesRef: notesDocRef.id,
            origin: 'manual',
          };

          await addDoc(collection(db, 'stacks'), stack);
          
          // Clear localStorage after successful submission
          const params = new URLSearchParams(window.location.search);
          const tempId = params.get('tempId');
          if (tempId) {
            localStorage.removeItem(`unsavedStack_${tempId}`);
          }
          
          navigate('/dashboard');
        }
      } catch (error) {
        setErrorMessage('Error saving stack: ' + error.message);
      }
    } else {
      setErrorMessage('No user is logged in');
    }
  };

  const handleSupplementChange = (index, field, value) => {
    const newSelectedSupplements = [...selectedSupplements];
    newSelectedSupplements[index] = { ...newSelectedSupplements[index], [field]: value };
    setSelectedSupplements(newSelectedSupplements);
  };

  const addSupplement = (newSupplementData) => {
    setSelectedSupplements([...selectedSupplements, newSupplementData]);
  };

  const removeSupplement = (index) => {
    const newSelectedSupplements = selectedSupplements.filter((_, i) => i !== index);
    setSelectedSupplements(newSelectedSupplements);
  };

  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
    if (editMode !== index) {
      setExpandedRow(index);
    }
  };

  const handleAnalyzeStack = async () => {
    try {
      setErrorMessage('');
      setIsAnalyzing(true);
      setIsModalOpen(true);
      
      const token = await auth.currentUser.getIdToken();
      const age = calculateAge(userProfile.dateOfBirth);
      
      const response = await axios.post(`${BASE_URL}/analyze-stack`, {
        healthGoals,
        supplements: selectedSupplements.map(supplement => ({
          name: supplement.name,
          dosage: `${supplement.dosage} ${supplement.unit}`
        })),
        age,
        gender: userProfile.gender,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      
      setAnalysisResults(response.data);
    } catch (error) {
      console.error('Analysis failed:', error);
      setErrorMessage('Failed to analyze stack: ' + error.message);
    } finally {
      setIsAnalyzing(false);
    }
  };

  const handleAddSupplement = () => {
    // Implement add supplement functionality
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container component="main" maxWidth="md" sx={{ px: { xs: 1, sm: 2 } }}>
      <Paper 
        elevation={0} 
        sx={{ 
          padding: { xs: 2, sm: 2.5 },
          marginTop: 1,
          marginBottom: 2,
          borderRadius: '16px',
          background: '#fff',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
        }}
      >
        <Typography
          component="h1"
          variant="h5"
          sx={{ 
            fontFamily: 'Lora, serif',
            fontWeight: 700,
            fontSize: '24px',
            letterSpacing: '0.1px',
            background: 'linear-gradient(90deg, #9EB384 15%, #435334 100%)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            textShadow: '0 1px 2px rgba(0,0,0,0.1)',
            textAlign: 'center',
            mb: 2
          }}
        >
          {isEditMode ? 'Edit Stack' : 'Create Stack'}
        </Typography>

        {/* Stack Name Section */}
        <Box sx={{ mb: 2 }}>
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center',
            gap: 1,
            flexWrap: 'wrap'
          }}>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ minWidth: 'fit-content' }}
            >
              Stack Name:
            </Typography>
            <TextField
              value={stackName}
              onChange={(e) => setStackName(e.target.value)}
              variant="standard"
              fullWidth
              required
              sx={{
                maxWidth: { xs: '100%', sm: '400px' },
                '& .MuiInput-root': {
                  fontSize: '1.1rem',
                  fontWeight: 500,
                  '&:before': {
                    borderBottom: '2px solid #E2E8F0'
                  },
                  '&:hover:not(.Mui-disabled):before': {
                    borderBottom: '2px solid #CBD5E0'
                  },
                  '&.Mui-focused:after': {
                    borderBottom: '2px solid #4299E1'
                  }
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      size="small"
                      sx={{ 
                        color: '#A0AEC0',
                        padding: '4px',
                        '&:hover': {
                          color: '#4A5568'
                        }
                      }}
                    >
                      <EditIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </Box>
        </Box>

        {/* Health Goals Section */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ 
            fontSize: '1rem',
            fontWeight: 600,
            color: '#2D3748',
            mb: 2
          }}>
            Health Goals
          </Typography>
          <TextField
            fullWidth
            multiline
            rows={3}
            placeholder="What are your health goals? This helps AI provide better recommendations."
            value={healthGoals}
            onChange={(e) => setHealthGoals(e.target.value)}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: '#F7FAFC',
                '&:hover': {
                  backgroundColor: '#EDF2F7',
                },
                '&.Mui-focused': {
                  backgroundColor: '#F7FAFC',
                  boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.2)',
                }
              }
            }}
          />
        </Box>

        {/* Supplements Table Section */}
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6" sx={{ 
            fontSize: '1rem',
            fontWeight: 600,
            color: '#2D3748',
            mb: 2,
            px: { xs: 1, sm: 0 }
          }}>
            Your Supplements
          </Typography>

          <SupplementsTable
            selectedSupplements={selectedSupplements}
            setSelectedSupplements={setSelectedSupplements}
            notes={notes}
            setNotes={setNotes}
          />
        </Box>

        {/* Action Buttons below table */}
        <Box sx={{ 
          display: 'flex',
          justifyContent: 'flex-start',
          mt: 3,
          alignItems: 'center'
        }}>
          <Button
            variant="outlined"
            onClick={handleAnalyzeStack}
            disabled={isAnalyzing}
            sx={{
              ...buttonStyles.secondary,
              borderColor: '#435334',
              color: '#435334',
              '&:hover': {
                borderColor: '#364428',
                backgroundColor: 'rgba(67, 83, 52, 0.04)',
              }
            }}
          >
            <Box sx={buttonStyles.iconWrapper}>
              {isAnalyzing ? (
                <CircularProgress size={20} sx={{ color: '#435334' }} />
              ) : (
                <AutoFixHighIcon sx={{ fontSize: '1rem' }} />
              )}
              <span>{isAnalyzing ? 'Analyzing...' : 'Analyze Stack'}</span>
            </Box>
          </Button>
        </Box>

        {/* Analysis Results Modal */}
        <Dialog
          open={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          maxWidth="md"
          fullWidth
          PaperProps={{
            sx: {
              margin: 0,
              position: 'fixed',
              width: { xs: '90%', sm: '80%' },
              maxHeight: { 
                xs: '80vh',
                sm: '80vh' 
              },
              borderRadius: '16px',
              padding: { xs: 1.5, sm: 2 },
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              overflowY: 'auto',
              zIndex: 9999,
            }
          }}
          sx={{
            zIndex: 9999,
            '& .MuiDialog-container': {
              alignItems: 'center'
            },
            '& .MuiBackdrop-root': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
          }}
        >
          <DialogTitle sx={{ 
            p: { xs: 1, sm: 2 },
            display: 'flex', 
            justifyContent: 'space-between', 
            alignItems: 'center',
            color: '#435334',
            fontWeight: 600
          }}>
            AI Analysis Results
            <IconButton 
              onClick={() => setIsModalOpen(false)}
              sx={{ color: '#718096' }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent sx={{ 
            p: { xs: 1, sm: 2 },
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#CBD5E0',
              borderRadius: '4px',
            }
          }}>
            {isAnalyzing ? (
              <Box sx={{ 
                display: 'flex', 
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '200px',
                gap: 2
              }}>
                <CircularProgress size={40} sx={{ color: '#435334' }} />
                <Typography variant="body1" sx={{ color: '#435334' }}>
                  Analyzing your stack...
                </Typography>
              </Box>
            ) : (
              <>
                {/* Effectiveness Section */}
                {analysisResults?.effectiveness && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" sx={{ color: '#2D3748', mb: 1 }}>
                      Effectiveness
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ 
                        color: '#4A5568',
                        whiteSpace: 'pre-line',
                        backgroundColor: '#F7FAFC',
                        p: { xs: 1.5, sm: 2 },
                        borderRadius: '8px',
                        border: '1px solid #E2E8F0'
                      }}
                    >
                      {analysisResults.effectiveness}
                    </Typography>
                  </Box>
                )}

                {/* Interactions Section */}
                {analysisResults?.interactions && (
                  <Box sx={{ mb: 3 }}>
                    <Typography variant="subtitle2" sx={{ color: '#2D3748', mb: 1 }}>
                      Interactions
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ 
                        color: '#4A5568',
                        whiteSpace: 'pre-line',
                        backgroundColor: '#F7FAFC',
                        p: { xs: 1.5, sm: 2 },
                        borderRadius: '8px',
                        border: '1px solid #E2E8F0'
                      }}
                    >
                      {analysisResults.interactions}
                    </Typography>
                  </Box>
                )}

                {/* Recommendations Section */}
                {analysisResults?.recommendations && (
                  <Box sx={{ mb: 2 }}>
                    <Typography variant="subtitle2" sx={{ color: '#2D3748', mb: 1 }}>
                      Recommendations
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ 
                        color: '#4A5568',
                        whiteSpace: 'pre-line',
                        backgroundColor: '#F7FAFC',
                        p: { xs: 1.5, sm: 2 },
                        borderRadius: '8px',
                        border: '1px solid #E2E8F0'
                      }}
                    >
                      {analysisResults.recommendations}
                    </Typography>
                  </Box>
                )}
              </>
            )}
          </DialogContent>
        </Dialog>

        {/* Notes Section */}
        <Box sx={{ mt: 2 }}>
          <TextField
            fullWidth
            multiline
            rows={3}
            placeholder="Add notes about your stack (optional)"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            variant="outlined"
            size="small"
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: '12px',
                backgroundColor: '#F7FAFC',
                '&:hover': {
                  backgroundColor: '#EDF2F7',
                },
                '&.Mui-focused': {
                  backgroundColor: '#F7FAFC',
                  boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.2)',
                }
              }
            }}
          />

          {/* Error Message */}
          {errorMessage && (
            <Typography 
              color="error" 
              variant="body2" 
              sx={{ mt: 1 }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>

        {/* Footer Buttons */}
        <Box sx={{ 
          mt: 3,
          display: 'flex',
          flexDirection: { xs: 'column', sm: 'row' },
          justifyContent: 'flex-end',
          gap: 2
        }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/dashboard')}
            fullWidth={isMobile}
            sx={buttonStyles.secondary}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={handleSubmit}
            fullWidth={isMobile}
            sx={buttonStyles.primary}
          >
            {isEditMode ? 'Update Stack' : 'Save Stack'}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default StackForm;