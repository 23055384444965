import React, { useState, useRef, useEffect } from 'react';
import {
  Container,
  Typography,
  Box,
  Button,
  TextField,
  Paper,
  Chip,
  CircularProgress,
  InputAdornment,
  IconButton
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useNavigate } from 'react-router-dom';
import { getAuth } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase';
import axios from 'axios';
import useUserProfile from '../hooks/useUserProfile';
import SupplementsTable from './SupplementsTable';
import config from '../config';
import '../common.css';
import { useTheme } from '@mui/material/styles';
import { calculateAge } from '../Utils';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import EditIcon from '@mui/icons-material/Edit';
import { buttonStyles } from './common/ButtonStyles';

const { BASE_URL } = config;

function CreateStack() {
  const [stackName, setStackName] = useState('');
  const [healthGoals, setHealthGoals] = useState('');
  const [selectedSupplements, setSelectedSupplements] = useState([]);
  const [loadingAI, setLoadingAI] = useState(false);
  const [expandedRow, setExpandedRow] = useState(null);
  const [editMode, setEditMode] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [showStackDetails, setShowStackDetails] = useState(false);
  const [notes, setNotes] = useState('');
  const { userProfile } = useUserProfile();
  const navigate = useNavigate();
  const theme = useTheme();

  // Ref for scrolling to stack details
  const stackDetailsRef = useRef(null);

  const sampleGoals = [
    'Weight Management',
    'Muscle Health',
    'Digestion ',
    'Energy ',
    'Sleep Hygiene',
    'Energy ',
    'Heart Health',
    'Focus',
    'Stress Relief ',
    'Immunity',
    'GLP-1 Agonist nutrional support',
    'Healthy Hair & Skin ',
    'Healthy Aging ',
    'Memory ',
    'Joint Health',
    'Bone Health ',
    'Hormone Balance ',
    'Prostate Health ',
    'Eye Health',
    'Prenatal & Postnatal',
    'Healthy Mood',
    'Gluclose Metabolism '
  ];

  const handleSubmit = async (event) => {
    event.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!stackName) {
      setErrorMessage('Stack name is required.');
      return;
    }

    if (user) {
      try {
        // Create health goals document
        const healthGoalDocRef = await addDoc(collection(db, 'healthGoals'), {
          goals: healthGoals,
          userId: user.uid
        });

        // Create notes document
        const notesDocRef = await addDoc(collection(db, 'notes'), {
          content: notes,
          userId: user.uid
        });

        // Create stack document with references to health goals and notes
        const stack = {
          name: stackName,
          userId: user.uid,
          supplements: selectedSupplements,
          healthGoalsRef: healthGoalDocRef.id,
          notesRef: notesDocRef.id,
          origin: 'AI'
        };

        await addDoc(collection(db, 'stacks'), stack);

        // Clear the saved state after successful submission
        const params = new URLSearchParams(window.location.search);
        const tempId = params.get('tempId');
        if (tempId) {
          localStorage.removeItem(`unsavedStack_${tempId}`);
        }

        navigate('/dashboard');
      } catch (error) {
        setErrorMessage('Error creating stack: ' + error.message);
      }
    } else {
      setErrorMessage('You need to login to create a stack.');
      navigate('/login');
    }
  };

  const MAX_REQUESTS = 3;  // Maximum number of requests allowed for non-logged-in users

  const handleGenerateStack = async () => {
    // Get the current request count from localStorage (or default to 0 if not set)
    let requestCount = parseInt(localStorage.getItem('stackRequestCount')) || 0;
  
    // Clear previous stack details
    setStackName('');
    setSelectedSupplements([]);
    setShowStackDetails(false);
    setNotes('');
  
    setLoadingAI(true);
    const auth = getAuth();
    const user = auth.currentUser;
  
    // Declare variables for stack generation
    let token = '';
    let calculatedAge = 'unknown';
    let gender = 'unknown';
  
    try {
      if (user) {
        token = await auth.currentUser.getIdToken();
        calculatedAge = calculateAge(userProfile.dateOfBirth);
        gender = userProfile.gender;
      }
      else {
        if (requestCount >= MAX_REQUESTS) {
          alert("You have reached the maximum number of stack generations. Please log in to continue.");
          navigate('/login');
          return;
        }
      }
  
      const response = await axios.post(`${BASE_URL}/generate-stack`, {
        healthGoals,
        age: calculatedAge,
        gender: gender
      }, {
        headers: {
          Authorization: `Bearer ${token}`  // This will be empty for non-logged-in users
        }
      });
  
      // Set the AI-generated stack data
      setStackName(response.data.name);
      setSelectedSupplements(response.data.supplements);
      setShowStackDetails(true);
  
      // Generate tempId if not exists
      const params = new URLSearchParams(window.location.search);
      if (!params.get('tempId')) {
        const tempId = 'temp_' + Date.now();
        params.set('tempId', tempId);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        window.history.replaceState({}, '', newUrl);
      }
  
      // Scroll to the stack details section
      setTimeout(() => {
        stackDetailsRef.current?.scrollIntoView({ behavior: 'smooth' });
      }, 0);
  
      // Increment the request count and store it back in localStorage
      requestCount++;
      localStorage.setItem('stackRequestCount', requestCount);
  
    } catch (error) {
      console.error('Error generating stack:', error);
    } finally {
      setLoadingAI(false);
    }
  };
  const toggleRowExpansion = (index) => {
    setExpandedRow(expandedRow === index ? null : index);
  };

  const toggleEditMode = (index) => {
    setEditMode(editMode === index ? null : index);
    if (editMode !== index) {
      setExpandedRow(index);
    }
  };

  const handleSampleGoalClick = (goal) => {
    const currentGoals = healthGoals ? healthGoals.split(',').map(g => g.trim()) : [];
    if (!currentGoals.includes(goal)) {
      const newGoals = [...currentGoals, goal].join(', ');
      setHealthGoals(newGoals);
    }
  };
 

  // Add this effect to check for returning stack state
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tempId = params.get('tempId');
    
    if (tempId) {
      const savedStack = localStorage.getItem(`unsavedStack_${tempId}`);
      if (savedStack) {
        try {
          const { 
            supplements, 
            stackName: savedName, 
            healthGoals: savedGoals,
            notes: savedNotes, 
            timestamp 
          } = JSON.parse(savedStack);
          
          const oneHour = 60 * 60 * 1000;
          if (new Date().getTime() - timestamp < oneHour) {
            if (supplements) setSelectedSupplements(supplements);
            if (savedName) setStackName(savedName);
            if (savedGoals) setHealthGoals(savedGoals);
            if (savedNotes) setNotes(savedNotes);
            setShowStackDetails(true);
            
            // Clear tempId from URL without page reload
            const newUrl = new URL(window.location.href);
            newUrl.searchParams.delete('tempId');
            window.history.replaceState({}, '', newUrl);
          }
        } catch (error) {
          console.error('Error restoring stack state:', error);
        }
      }
    }
  }, []); // Run once on component mount

  return (
    <Container component="main" maxWidth="md">
      <Paper 
        elevation={0} 
        sx={{ 
          padding: { xs: 2, sm: 2.5 },
          marginTop: 1,
          marginBottom: 2,
          borderRadius: '16px',
          background: '#fff',
          boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
        }}
      >
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          gap: 1.5 
        }}>
          {/* Updated Header with more striking brand styling */}
          <Typography
            component="h1"
            variant="h5"
            sx={{ 
              fontFamily: 'Lora, serif',
              fontWeight: 700,
              fontSize: '24px',
              letterSpacing: '0.1px',
              // Reversed gradient to fade in left to right
              background: 'linear-gradient(90deg, #9EB384 15%, #435334 100%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              // Crisp shadow
              textShadow: '0 1px 2px rgba(0,0,0,0.1)',
              padding: '4px 0',
              mb: 0,
              transition: 'transform 0.2s ease',
              '&:hover': {
                transform: 'translateY(-1px)',
              }
            }}
          >
            myStack AI
          </Typography>
          
          <Typography 
            variant="body2"
            color="text.secondary"
            textAlign="center"
            sx={{ 
              maxWidth: '400px',
              lineHeight: 1.5,
            }}
          >
            Let myStack AI analyze your health goals and recommend a personalized supplement stack
          </Typography>

          {/* Main Input Section - More compact */}
          <Box sx={{ width: '100%', maxWidth: '500px' }}>
            <TextField
              fullWidth
              multiline
              rows={2}
              placeholder="What are your health goals? Be specific about what you want to achieve..."
              value={healthGoals}
              onChange={(e) => setHealthGoals(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#F7FAFC',
                  '&:hover': {
                    backgroundColor: '#EDF2F7',
                  },
                  '&.Mui-focused': {
                    backgroundColor: '#F7FAFC',
                    boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.2)',
                  }
                }
              }}
              InputProps={{
                endAdornment: healthGoals && (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setHealthGoals('')}
                      edge="end"
                      size="small"
                      sx={{ mr: 0.5 }}
                    >
                      <ClearIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* Sample Goals Chips - Two-line vertical scroll */}
            <Box sx={{ mt: 1.5 }}>
              <Box 
                sx={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  mb: 1
                }}
              >
                <Typography 
                  variant="caption" 
                  color="text.secondary"
                  sx={{ fontWeight: 500 }}
                >
                  Quick add:
                </Typography>
              </Box>
              <Box 
                className="scrollable-chips" 
                sx={{ 
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: 0.5,
                  maxHeight: '56px',
                  overflowY: 'auto',
                  pb: 0.5,
                  position: 'relative',
                  backgroundColor: '#F7FAFC',
                  '&::after': {
                    content: '""',
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '12px',
                    background: 'linear-gradient(to top, rgba(0,0,0,0.05) 0%, transparent 100%)',
                    pointerEvents: 'none',
                    borderBottomLeftRadius: '8px',
                    borderBottomRightRadius: '8px',
                  },
                  '&::-webkit-scrollbar': {
                    width: '4px',
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-track': {
                    backgroundColor: 'transparent',
                  },
                  '&::-webkit-scrollbar-thumb': {
                    backgroundColor: 'rgba(24, 80, 2, 0.2)',
                    borderRadius: '2px',
                    '&:hover': {
                      backgroundColor: 'rgba(24, 80, 2, 0.3)',
                    }
                  },
                  border: '1px solid #E2E8F0',
                  borderRadius: '8px',
                  padding: '4px',
                }}
              >
                {sampleGoals.map((goal, index) => (
                  <Chip
                    key={index}
                    label={goal}
                    onClick={() => handleSampleGoalClick(goal)}
                    size="small"
                    sx={{
                      borderRadius: '6px',
                      height: '24px',
                      fontSize: '0.80rem',
                      backgroundColor: '#fff',
                      border: '1px solid #E2E8F0',
                      '&:hover': {
                        backgroundColor: '#EDF2F7',
                        borderColor: '#CBD5E0',
                      },
                    }}
                  />
                ))}
              </Box>
            </Box>

            {/* Recommend Button - More compact */}
            <Button
              variant="contained"
              onClick={handleGenerateStack}
              disabled={loadingAI || !healthGoals.trim()}
              size="medium"
              sx={{
                mt: 4,
                ...buttonStyles.primary,
              }}
            >
              {loadingAI ? (
                <Box sx={buttonStyles.iconWrapper}>
                  <CircularProgress size={16} color="inherit" />
                  <span>Analyzing...</span>
                </Box>
              ) : (
                <Box sx={buttonStyles.iconWrapper}>
                  <AutoFixHighIcon sx={{ fontSize: '1rem' }} />
                  <span>Recommend Stack</span>
                </Box>
              )}
            </Button>
          </Box>
        </Box>
      </Paper>

      {/* Stack Results Section */}
      {showStackDetails && (
        <Box ref={stackDetailsRef} sx={{ mt: 3 }}>
          {/* Stack Name Section */}
          <Paper
            elevation={0}
            sx={{
              mb: 2,
              p: { xs: 2, sm: 2.5 },
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            }}
          >
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              flexWrap: 'wrap'  // Allows wrapping on mobile
            }}>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ minWidth: 'fit-content' }}
              >
                Stack Name:
              </Typography>
              <TextField
                value={stackName}
                onChange={(e) => setStackName(e.target.value)}
                variant="standard"
                fullWidth
                sx={{
                  maxWidth: { xs: '100%', sm: '400px' },
                  '& .MuiInput-root': {
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    '&:before': {
                      borderBottom: '2px solid #E2E8F0'
                    },
                    '&:hover:not(.Mui-disabled):before': {
                      borderBottom: '2px solid #CBD5E0'
                    },
                    '&.Mui-focused:after': {
                      borderBottom: '2px solid #4299E1'
                    }
                  },
                  '& .MuiInput-input': {
                    padding: '4px 0'
                  }
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        size="small"
                        onClick={() => {
                          const input = document.querySelector('input[value="' + stackName + '"]');
                          if (input) {
                            input.focus();
                            input.select();
                          }
                        }}
                        sx={{ 
                          color: '#A0AEC0',
                          padding: '4px',
                          '&:hover': {
                            color: '#4A5568'
                          }
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Box>
          </Paper>

          <SupplementsTable
            selectedSupplements={selectedSupplements}
            setSelectedSupplements={setSelectedSupplements}
            notes={notes}
            setNotes={setNotes}
          />
          
          {/* Notes and Save Section */}
          <Paper
            elevation={0}
            sx={{
              mt: 2,
              p: { xs: 2, sm: 2.5 },
              borderRadius: '16px',
              background: '#fff',
              boxShadow: '0 2px 12px rgba(0,0,0,0.08)',
            }}
          >
            <TextField
              fullWidth
              multiline
              rows={3}
              placeholder="Add notes about your stack (optional)"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              variant="outlined"
              size="small"
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '12px',
                  backgroundColor: '#F7FAFC',
                  '&:hover': {
                    backgroundColor: '#EDF2F7',
                  },
                  '&.Mui-focused': {
                    backgroundColor: '#F7FAFC',
                    boxShadow: '0 0 0 2px rgba(66, 153, 225, 0.2)',
                  }
                }
              }}
            />

            <Box sx={{ 
              mt: 2,
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2
            }}>
              <Button
                variant="outlined"
                onClick={() => navigate('/dashboard')}
                sx={buttonStyles.secondary}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={handleSubmit}
                sx={buttonStyles.primary}
              >
                Save Stack
              </Button>
            </Box>
          </Paper>
        </Box>
      )}
    </Container>
  );
}

export default CreateStack;
                     