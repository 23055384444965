import { createTheme } from '@mui/material/styles';

// Add custom color palette
const colors = {
  green: {
    main: '#185002',      // Dark base green
    light: '#3498db',     // Updated: More muted light green (closer to dark)
    medium: '#2E8B57',    // Medium sea green
    dark: '#276B42',      // Darker green for gradient
    hover: '#133B02',     // Hover state
    transparent: {
      light: 'rgba(46, 139, 87, 0.04)',
      medium: 'rgba(46, 139, 87, 0.08)',
    },
    shadow: 'rgba(46, 139, 87, 0.25)',
  },
  grey: {
    border: '#E2E8F0',    // Good subtle border
    background: '#F7FAFC', // Good light background
    text: '#718096',      // Good secondary text
    dark: '#2D3748',      // Good primary text
    disabled: '#A0AEC0',  // Good disabled state
  }
};

const theme = createTheme({
  palette: {
    primary: {
      main: colors.green.main,
      light: colors.green.light,
      medium: colors.green.medium,
      hover: colors.green.hover,
    },
    secondary: {
      main: '#FFC107',
    },
    grey: colors.grey,
  },
  // Reversed gradient direction
  gradients: {
    primary: `linear-gradient(20deg, ${colors.green.light} 20%, ${colors.green.dark} 90%)`,
    hover: `linear-gradient(45deg, ${colors.green.light} 40%, ${colors.green.dark} 90%)`,
  },
  // Add custom shadows
  customShadows: {
    button: `0 2px 8px ${colors.green.shadow}`,
    buttonHover: `0 4px 15px rgba(46, 139, 87, 0.35)`,  // Increased shadow opacity for hover
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            background: `${colors.grey.disabled} !important`,
            color: '#fff !important',
            boxShadow: 'none !important',
          }
        }
      }
    }
  }
});

// Export both theme and colors
export { colors };
export default theme;
