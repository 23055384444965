// CreateStackButton.js

import React, { useState } from 'react';
import { 
  Button, 
  Menu, 
  MenuItem, 
  ListItemIcon, 
  ListItemText,
  Typography,
  Box,
  Divider
} from '@mui/material';
import { 
  AutoAwesome as AutoAwesomeIcon, 
  ListAlt as ListAltIcon,
  ArrowForward as ArrowForwardIcon 
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { buttonStyles } from './common/ButtonStyles';

function CreateStackButton() {
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();

  return (
    <>
      <Button
        variant="contained"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        sx={{
          ...buttonStyles.primary,
          padding: '8px 32px'
        }}
      >
        Create a Stack
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{
          elevation: 3,
          sx: {
            width: 320,
            borderRadius: '12px',
            mt: 1.5,
            padding: '12px',
            backgroundColor: '#FAFAFA',
            '& .MuiMenuItem-root': {
              borderRadius: '12px',
              mb: 1,
              backgroundColor: '#FFFFFF',
              border: '1px solid #EDF2F7',
              transition: 'all 0.2s ease',
              '&:hover': {
                backgroundColor: 'rgba(46, 139, 87, 0.04)',
                transform: 'translateY(-1px)',
                boxShadow: '0 2px 8px rgba(46, 139, 87, 0.12)',
                border: '1px solid #2E8B57'
              },
              '&:last-child': {
                mb: 0
              }
            }
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem 
          onClick={() => {
            navigate('/create-stack');
            setAnchorEl(null);
          }}
          sx={{
            py: 2,
            whiteSpace: 'normal',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            flex: 1,
            width: '100%'
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 1,
              width: '100%'
            }}>
              <ListItemIcon sx={{ 
                color: '#2E8B57',
                minWidth: '36px'
              }}>
                <AutoAwesomeIcon />
              </ListItemIcon>
              <Typography 
                variant="subtitle1"
                sx={{ 
                  fontWeight: 600,
                  color: '#2D3748',
                  flex: 1
                }}
              >
                Create with AI
              </Typography>
              <ArrowForwardIcon sx={{ 
                fontSize: '1.1rem',
                color: '#2E8B57',
                ml: 1
              }} />
            </Box>
            <Typography 
              variant="body2" 
              sx={{ 
                pl: '36px',
                color: '#718096',
                pr: '36px',
                lineHeight: 1.4
              }}
            >
              Get personalized supplement recommendations based on your health goals
            </Typography>
          </Box>
        </MenuItem>

        <MenuItem 
          onClick={() => {
            navigate('/manual-create-stack');
            setAnchorEl(null);
          }}
          sx={{
            py: 2,
            whiteSpace: 'normal',
          }}
        >
          <Box sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            flex: 1,
            width: '100%'
          }}>
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 1,
              width: '100%'
            }}>
              <ListItemIcon sx={{ 
                color: '#2E8B57',
                minWidth: '36px'
              }}>
                <ListAltIcon />
              </ListItemIcon>
              <Typography 
                variant="subtitle2" 
                sx={{ 
                  fontWeight: 600,
                  color: '#2D3748',
                  flex: 1
                }}
              >
                Add Current Stack
              </Typography>
              <ArrowForwardIcon sx={{ 
                fontSize: '1.1rem',
                color: '#A0AEC0',
                ml: 1
              }} />
            </Box>
            <Typography 
              variant="body2" 
              color="text.secondary"
              sx={{ 
                pl: '36px',
                pr: '36px',
                lineHeight: 1.4
              }}
            >
              Track and manage supplements you're already taking
            </Typography>
          </Box>
        </MenuItem>
      </Menu>
    </>
  );
}

export default CreateStackButton;