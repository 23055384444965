import {React, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Container, CssBaseline, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Home from './components/Home';
import Conditions from './components/Conditions';
import Supplements from './components/Supplements';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import CreateStack from './components/CreateStack';
import EditStack from './components/EditStack';
import Profile from './components/Profile';
import BottomNav from './components/BottomNav';
import ViewStack from './components/ViewStack';
import ContactUs from './components/ContactUs';
import Footer from './components/Footer'; 
import PrivacyPolicy from './components/PrivacyPolicy';
import { useAuth } from './AuthContext';
import ManualCreateStack from './components/ManualCreateStack';
import LandingPage from './components/LandingPage';
import VerifyEmail from './components/VerifyEmail';
import './App.css';
import TermsOfUse from './components/TermsOfUse';
import Detail from './components/Detail';
import Article from './components/Article'; // Import the new ArticleDetail component
import Research from './components/Research'; // Import Research component


function App() {
  const { currentUser } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Router>
      <CssBaseline />
      <TrackPageView /> {/* Component to handle tracking */}
      <Box display="flex" flexDirection="column" minHeight="100vh">
        <Header /> 
        <Box component="main" sx={{ flexGrow: 1, mb: isMobile ? '64px' : 0 }}> 
          <Routes>
            <Route path="/" element={currentUser ? <Navigate to="/dashboard" /> : <LandingPage />} />
            <Route path="/login" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/conditions" element={<Conditions />}>
              <Route path=":conditionKey" element={<Detail />}>
                <Route path="research" element={<Research />}>
                  <Route path=":articleId" element={<Article />} />
                </Route>
              </Route>
            </Route>
            <Route path="/supplements" element={<Supplements />}>
              <Route path=":supplementKey" element={<Detail />}>
                <Route path="research" element={<Research />}>
                  <Route path=":articleId" element={<Article />} />
                </Route>
              </Route>
            </Route>

            <Route path="/dashboard" element={currentUser ? <Dashboard /> : <Navigate to="/" />} />
            <Route path="/create-stack" element={<CreateStack />} />
            <Route path="/manual-create-stack" element={<ManualCreateStack />} />
            <Route path="/edit-stack/:stackId" element={<EditStack />} />
            <Route path="/view-stack/:stackId" element={<ViewStack />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-use" element={<TermsOfUse />} />
            <Route path="/verify-email" element={<VerifyEmail />} />
          </Routes>
        </Box>
        <Footer />
        {isMobile && <BottomNav />}
      </Box>
    </Router>
  );
}

// Create a separate component to track page views
function TrackPageView() {
  const location = useLocation();

  useEffect(() => {
    // This will track page views
    if (window.gtag) {
      window.gtag('config', 'AW-16692189167', {
        page_path: location.pathname,
      });
    }
  }, [location]);

  return null;
}

export default App;
